import { GetterTree } from "vuex";
import GeneralState from "../states/GeneralState";
import { Publish_GalleryViewModel } from "@/data/publish/Publish_GalleryViewModel";
import { Publish_RouteViewModel } from "@/data/publish/Publish_RouteViewModel";

export default {
  dataSourceUrl(): string {
    return GeneralState.general.dataSourceUrl;
  },
  scrollPosition(): number {
    return GeneralState.general.scrollPosition;
  },
  galleries(): Array<Publish_GalleryViewModel> {
    return GeneralState.general.galleries;
  },
  publishId(): string {
    return GeneralState.general.publishId;
  },
  routes(): Array<Publish_RouteViewModel> {
    return GeneralState.general.routes;
  },
  translations(): Record<string, string> {
    return GeneralState.general.translations;
  },
} as GetterTree<typeof GeneralState, unknown>;
