import Row from "./Row";
import { RowType } from "./RowType";
import Image from "./Image";

export default class RowSingle implements Row {
  private readonly type: RowType = RowType.Single;
  private data: Array<Image>;

  constructor(image: Image, type: RowType = RowType.Single) {
    this.data = [image];
    this.type = type;
  }

  getType(): RowType {
    return this.type;
  }

  getData(): Image[] {
    return this.data;
  }

  getSize(): number {
    return 1;
  }
}
