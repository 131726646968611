export default class Image {
  private path: string;
  private width: number;
  private height: number;

  constructor(path: string, width: number, height: number) {
    this.path = path;
    this.width = width;
    this.height = height;
  }

  getPath(): string {
    return this.path;
  }

  getWidth(): number {
    return this.width;
  }

  getHeight(): number {
    return this.height;
  }
}
