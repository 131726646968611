import { MutationTree } from "vuex";
import GeneralState from "../states/GeneralState";
import { Publish_GalleryViewModel } from "@/data/publish/Publish_GalleryViewModel";
import { Publish_RouteViewModel } from "@/data/publish/Publish_RouteViewModel";

export const enum GeneralMutations {
  SET_DATA_SOURCE_URL = "SET_DATA_SOURCE_URL",
  SET_SCROLL_POSITION = "SET_SCROLL_POSITION",
  SET_GALLERIES = "SET_GALLERIES",
  SET_PUBLISH_ID = "SET_PUBLISH_ID",
  SET_ROUTES = "SET_ROUTES",
  SET_TRANSLATIONS = "SET_TRANSLATIONS",
}
export const enum GeneralActions {
  SET_DATA_SOURCE_URL = "SET_DATA_SOURCE_URL",
  SET_SCROLL_POSITION = "SET_SCROLL_POSITION",
  SET_GALLERIES = "SET_GALLERIES",
  SET_PUBLISH_ID = "SET_PUBLISH_ID",
  SET_ROUTES = "SET_ROUTES",
  SET_TRANSLATIONS = "SET_TRANSLATIONS",
}

export default {
  [GeneralMutations.SET_DATA_SOURCE_URL]: (state, url: string) => {
    state.general.dataSourceUrl = url;
  },
  [GeneralMutations.SET_SCROLL_POSITION]: (state, scrollPosition: number) => {
    state.general.scrollPosition = scrollPosition;
  },
  [GeneralMutations.SET_GALLERIES]: (
    state,
    galleries: Array<Publish_GalleryViewModel>
  ) => {
    state.general.galleries = galleries;
  },
  [GeneralMutations.SET_PUBLISH_ID]: (state, publishId: string) => {
    state.general.publishId = publishId;
  },
  [GeneralMutations.SET_ROUTES]: (
    state,
    routes: Array<Publish_RouteViewModel>
  ) => {
    state.general.routes = routes;
  },
  [GeneralMutations.SET_TRANSLATIONS]: (
    state,
    translations: Record<string, string>
  ) => {
    state.general.translations = translations;
  },
} as MutationTree<typeof GeneralState>;
