import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { GeneralState } from "../store/states/GeneralState";

Vue.use(VueRouter);

const createDefaultRouter = (isAppInitialized: boolean): VueRouter => {
  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [],
  });

  router.addRoute({
    path: "*",
    name: "404",
    component: () => {
      if (isAppInitialized) {
        router.push("latest");
      } else {
        router.push("maintenance");
      }
    },
  });

  if (!isAppInitialized) {
    router.addRoute({
      path: "/maintenance",
      name: "Maintenance",
      component: () => import("../view/MaintenanceView.vue"),
      props: {},
    } as RouteConfig);
  } else {
    const generalState = (store.state as GeneralState).general;
    for (const route of generalState.routes) {
      if (route.galleryId == null) {
        continue;
      }
      let name = route.text;
      if (name.includes("translation:")) {
        name = generalState.translations[name.replace("translation:", "")];
      }
      router.addRoute({
        path: `/${route.url}`,
        name: name,
        component: () => import("../view/GalleryView.vue"),
        props: {
          galleryId: route.galleryId,
        },
      } as RouteConfig);
    }

    router.addRoute({
      path: "/about",
      name: "About",
      component: () => import("../view/AboutView.vue"),
    });
  }

  return router;
};

export default createDefaultRouter;
