import Vue from "vue";
import Vuex from "vuex";
import GeneralActions from "./actions/GeneralActions";
import GeneralGetters from "./getters/GeneralGetters";
import GeneralMutations from "./mutations/GeneralMutations";
import GeneralState from "./states/GeneralState";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    ...GeneralState,
  },
  mutations: {
    ...GeneralMutations,
  },
  actions: {
    ...GeneralActions,
  },
  getters: {
    ...GeneralGetters,
  },
});
