import { Publish_GalleryViewModel } from "@/data/publish/Publish_GalleryViewModel";
import { Publish_RouteViewModel } from "@/data/publish/Publish_RouteViewModel";
export interface GeneralStateStore {
  dataSourceUrl: string;
  scrollPosition: number;
  galleries: Array<Publish_GalleryViewModel>;
  publishId: string;
  routes: Array<Publish_RouteViewModel>;
  translations: Record<string, string>;
}

export interface GeneralState {
  general: GeneralStateStore;
}

export default {
  general: {
    dataSourceUrl: "",
    scrollPosition: 0,
    galleries: [],
    publishId: "",
    routes: [],
    translations: {},
  },
} as GeneralState;
