








// @ is an alias to /src
import HeaderComponent from "@/component/shared/HeaderComponent.vue";
import FooterComponent from "@/component/shared/FooterComponent.vue";
import { Component, Vue } from "vue-property-decorator";
import { GeneralMutations } from "./store/mutations/GeneralMutations";

@Component({
  components: {
    HeaderComponent,
    FooterComponent,
  },
})
export default class App extends Vue {
  public loading = true;

  async created(): Promise<void> {
    window.addEventListener("scroll", this.setScrollPosition);
    this.loading = false;
  }

  destroyed(): void {
    window.removeEventListener("scroll", this.setScrollPosition);
  }

  setScrollPosition(): void {
    this.$store.commit(GeneralMutations.SET_SCROLL_POSITION, window.scrollY);
  }
}
