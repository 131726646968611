import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import "@/../node_modules/normalize.css/normalize.css";
import "@/../node_modules/hamburgers/dist/hamburgers.css";
import "@/assets/styles/index.scss";
import RequestService from "./service/RequestService";
import DeviceService from "./service/DeviceService";
import LanguageService from "./service/LanguageService";
import { GeneralState } from "./store/states/GeneralState";
import { GeneralMutations } from "./store/mutations/GeneralMutations";
import { Languages } from "./data/Languages";
import createDefaultRouter from "./router";
Vue.config.productionTip = false;

const languageService = new LanguageService();

const requestService = new RequestService(
  new DeviceService(),
  (store.state as GeneralState).general
);

(async () => {
  let isAppInitialized = true;
  store.commit(
    GeneralMutations.SET_DATA_SOURCE_URL,
    //"https://localhost:5001"
    "https://dashboard.melaniafurmaniak.pl"
  );

  try {
    store.commit(
      GeneralMutations.SET_PUBLISH_ID,
      await requestService.getPublishId()
    );

    await Promise.all([
      (async () =>
        store.commit(
          GeneralMutations.SET_ROUTES,
          await requestService.getRoutes()
        ))(),
      (async () =>
        store.commit(
          GeneralMutations.SET_TRANSLATIONS,
          await requestService.getTranslations(
            languageService.isPolish() ? Languages.PL : Languages.EN
          )
        ))(),
    ]);
  } catch (e) {
    isAppInitialized = false;
  }

  new Vue({
    store,
    router: createDefaultRouter(isAppInitialized),
    render: (h) => h(App),
    provide: {
      requestService: requestService,
      deviceService: new DeviceService(),
      languageService: languageService,
    },
  }).$mount("#app");
})();
