import isMobile from "ismobilejs";

export default class DeviceService {
  public isMobileDisplay(): boolean {
    const mobileDetector = isMobile(window.navigator.userAgent);
    return (
      window.innerWidth < 768 || mobileDetector.tablet || mobileDetector.phone
    );
  }

  public isElementOutOfViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top < 0 ||
      rect.bottom > document.documentElement.clientHeight ||
      rect.left < 0 ||
      rect.right > document.documentElement.clientWidth
    );
  }

  public isElementVisibleInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= document.documentElement.clientHeight ||
      rect.right >= document.documentElement.clientWidth
    );
  }

  public isSafariBrowser(): boolean {
    return (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    );
  }

  public hideScroll(): void {
    document.documentElement.className = "";
    document.body.style.overflowY = "hidden";
  }

  public disableScroll(): void {
    document.documentElement.className = "html__show-disabled-scroll";
    document.body.style.overflowY = "hidden";
  }

  public enableScroll(): void {
    document.documentElement.className = "";
    document.body.style.overflowY = "unset";
  }
}
