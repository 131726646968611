import Row from "@/data/Row";
import { DynamicLayoutType } from "./DynamicLayoutType";

export default class DynamicLayout {
  private type: DynamicLayoutType;
  private imagesCarousel: Array<Row>;
  private layout: Array<Row>;

  constructor(type: DynamicLayoutType, layout: Array<Row>) {
    this.type = type;
    this.layout = layout;
  }

  getType(): DynamicLayoutType {
    return this.type;
  }

  getLayout(): Array<Row> {
    return this.layout;
  }
}
