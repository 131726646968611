import { ActionTree } from "vuex";
import {
  GeneralActions,
  GeneralMutations,
} from "../mutations/GeneralMutations";

export default {
  [GeneralActions.SET_DATA_SOURCE_URL]: ({ commit }, url) => {
    commit(GeneralMutations.SET_SCROLL_POSITION, url);
  },
  [GeneralActions.SET_SCROLL_POSITION]: ({ commit }, scrollPosition) => {
    commit(GeneralMutations.SET_SCROLL_POSITION, scrollPosition);
  },
  [GeneralActions.SET_GALLERIES]: ({ commit }, galleries) => {
    commit(GeneralMutations.SET_GALLERIES, galleries);
  },
  [GeneralActions.SET_PUBLISH_ID]: ({ commit }, publishId) => {
    commit(GeneralMutations.SET_PUBLISH_ID, publishId);
  },
  [GeneralActions.SET_ROUTES]: ({ commit }, routes) => {
    commit(GeneralMutations.SET_ROUTES, routes);
  },
  [GeneralActions.SET_TRANSLATIONS]: ({ commit }, translations) => {
    commit(GeneralMutations.SET_TRANSLATIONS, translations);
  },
} as ActionTree<typeof GeneralActions, unknown>;
