






import { Component, Vue } from "vue-property-decorator";

@Component
export default class FooterComponent extends Vue {
  private year: number = new Date().getFullYear();
}
