import { RowType } from "./RowType";
import Row from "./Row";
import Image from "./Image";

export default class RowHorizontal implements Row {
  private readonly type: RowType = RowType.Horizontal;
  private data: Array<Image>;

  constructor(image1: Image, image2: Image) {
    this.data = [image1, image2];
  }

  getType(): RowType {
    return this.type;
  }

  getData(): Array<Image> {
    return this.data;
  }

  getSize(): number {
    return 2;
  }
}
