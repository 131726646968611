export enum Languages {
  PL = "pl",
  EN = "en",
}

const values = Object.keys(Languages).map((key) => (Languages as unknown)[key]);

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Languages {
  // eslint-disable-next-line no-inner-declarations
  export function getValues(): Array<Languages> {
    return values;
  }
}
