import Row from "./Row";
import { RowType } from "./RowType";
import Image from "./Image";

export default class RowVertical implements Row {
  private readonly type: RowType = RowType.Vertical;
  private data: Array<Image>;

  constructor(image1: Image, image2: Image, image3: Image) {
    this.data = [image1, image2, image3];
  }

  getType(): RowType {
    return this.type;
  }

  getData(): Array<Image> {
    return this.data;
  }

  getSize(): number {
    return 3;
  }
}
